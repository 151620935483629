<template>
    <div class="container rtl">
        <div class="row pb-4">
            <!-- <label class="btn btn-primary active m-2">
                نوێکردنەوە
                <input type="checkbox" v-model="resetable">
            </label>
            <button class="btn btn-info m-2" onclick="window.history.back()">
                <span>گەڕانەوە</span>
            </button> -->
            <div class="col">
                <table class="wasl-table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">ژ. پسوڵە</th>
                            <td scope="col" class="text-center">{{invoice.company_invoice_number}}</td>
                            <th scope="col" class="text-center">کۆمپانیا</th>
                            <td scope="col" class="text-center">{{invoice.company_name}}</td>
                            <th scope="col" class="text-center">بەروار</th>
                            <td scope="col" class="text-center">{{invoice.company_invoice_date}}</td>
                            <th scope="col" class="text-center">جۆری پسوڵە</th>
                            <td scope="col" class="text-center">
                                {{invoice.company_invoice_type == 'naqd' ? 'نەقد' : 'قەرز'}}</td>
                            <th scope="col" class="text-center">جۆری دراو</th>
                            <td scope="col" class="text-center">
                                {{invoice.company_invoice_currency == 'iqd' ? 'دینار' : 'دۆلار'}}</td>
                            <th scope="col" class="text-center">نرخی دۆلار</th>
                            <td scope="col" class="text-center">{{$service.toIQD(invoice.company_invoice_dollar_price)}}
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <form @submit.prevent="addcompany_invoice_items" autocomplete="off">
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>کۆدی بەرهەم</label>
                    <div class="form-group">
                        <input v-model="invoice_item.item_barcode" @input="checkBarcode" class="form-control" required>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label>ناوی بەرهەم</label>
                    <div class="form-group">
                        <select class="form-control" @change="checkItemID" v-model="invoice_item.item_id" required>
                            <option v-for="(item, index) in items" :key="index" :value="item.item_id">
                                {{item.item_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>کڕین بە</label>
                    <div class="form-group">
                        <select class="form-control" v-model="invoice_item.buy_type" required @change="checkLast">
                            <option value="dana">{{invoice_item.item_unit_tak}}</option>
                            <option value="karton">{{invoice_item.item_unit}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>بڕی هاتوو</label>
                    <input v-model="invoice_item.company_invoice_items_qty" class="form-control" type="number"
                        step="any" required>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی دۆلار</label>
                    <div class="input-group mb-3">
                        <input :disabled="invoice.company_invoice_currency == 'iqd'"
                            v-model="invoice_item.company_invoice_items_usd" class="form-control" type="number"
                            step="any" required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">$</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی دینار</label>
                    <div class="input-group mb-3">
                        <input :disabled="invoice.company_invoice_currency == 'usd'"
                            v-model="invoice_item.company_invoice_items_iqd" class="form-control" type="number"
                            step="any" required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">د.ع</span>
                        </div>

                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی کڕین پێشوو</label>
                    <div class="input-group mb-3">
                        <input v-model="temp_item.last_price" disabled class="form-control" type="number" step="any">
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                id="basic-addon1">{{invoice.company_invoice_currency == 'iqd' ? 'د.ع' : '$'}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>بڕی ماوە</label>
                    <div class="input-group mb-3">
                        <input v-model="temp_item.last_count" disabled class="form-control" type="number" step="any">
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                id="basic-addon1">{{invoice_item.buy_type == 'dana' ? invoice_item.item_unit_tak : invoice_item.item_unit}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                    <label>نرخی فرۆشتن</label>
                    <div class="input-group mb-3">
                        <input v-model="invoice_item.item_price" class="form-control" type="number" step="any" required>
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">د.ع</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <label>بەرواری بەسەرچون</label>
                    <input v-model="invoice_item.company_invoice_items_exp_date" class="form-control" type="date"
                        required>
                </div>
                <div class="col-2">
                    <label style="color:white">-</label>
                    <div class="form-group">
                        <button type="submit" class="btn btn-success">زیادکردن</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="row mt-2">
            <div class="col">
                <table class="my-table" border="1">
                    <thead>

                        <tr>
                            <th scope="col" class="text-center" rowspan="2"> بارکۆد </th>
                            <th scope="col" class="text-center" rowspan="2"> ناوی بەرهەم </th>
                            <th scope="col" class="text-center" rowspan="2"> جۆر </th>
                            <th scope="col" class="text-center" colspan="2">بڕ </th>
                            <th scope="col" class="text-center" colspan="2"> نرخی دینار </th>
                            <th scope="col" class="text-center" colspan="2"> نرخی دۆلار </th>
                            <th scope="col" class="text-center" rowspan="2"> کۆی نرخی دینار </th>
                            <th scope="col" class="text-center" rowspan="2"> کۆی نرخی دۆلار </th>
                            <th scope="col" class="text-center" rowspan="2">سڕینەوە</th>
                        </tr>
                        <tr>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                            <th class="text-center">تاک</th>
                            <th class="text-center">کۆ</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(item, index) in invoice.items" :key="index">
                            <td>{{item.item_barcode}}</td>
                            <td>{{item.item_name}}</td>
                            <td>{{item.category_name}}</td>
                            <td>{{item.company_invoice_items_qty}} {{item.item_unit_tak}}</td>
                            <td>{{(item.company_invoice_items_qty/item.item_quantity).toFixed(2)}} {{item.item_unit}}
                            </td>
                            <td>{{$service.toIQD(item.one_iqd)}}</td>
                            <td>{{$service.toIQD(item.many_iqd)}}</td>
                            <td>{{$service.toUSD(item.one_usd)}}</td>
                            <td>{{$service.toUSD(item.many_usd)}}</td>
                            <td>{{$service.toIQD(item.total_iqd)}}</td>
                            <td>{{$service.toUSD(item.total_usd)}}</td>
                            <td>
                                <button @click="opencompany_invoice_items(item , 'remove')" type="button"
                                    class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="text-center" colspan="3">کۆی گشتی</th>
                            <td class="text-center">{{invoice.qty}}</td>
                            <td class="text-center">{{invoice.qty_ko ? invoice.qty_ko.toFixed(2) : ''}}</td>
                            <td class="text-center" colspan="4">-</td>
                            <td class="text-center">{{$service.toIQD(invoice.iqd)}}</td>
                            <td class="text-center">{{$service.toUSD(invoice.usd)}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی بەرهەمی سەر پسوڵەی کۆمپانیا</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger"
                            @click="deletecompany_invoice_items">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                invoice_id: 0,
                invoice: {},
                selected_item: {
                    company_invoice_items_usd: 0,
                    company_invoice_items_iqd: 0,
                },
                invoice_item: {
                    company_invoice_items_usd: 0,
                    company_invoice_items_iqd: 0,
                    buy_type: 'karton'
                },
                resetable: false,
                temp_item: {},
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            items() {
                return this.$store.getters.items.filter(f => {
                    return f.store_id == this.invoice.store_id
                })
            },
        },
        methods: {
            checkLast() {
                let price = this.invoice_item.last_price
                let count = this.invoice_item.last_count
                if (this.invoice_item.buy_type == 'dana') {
                    this.temp_item.last_price = price
                    this.temp_item.last_count = count
                } else {
                    this.temp_item.last_price = (price * this.invoice_item.item_quantity)
                    this.temp_item.last_count = (count / this.invoice_item.item_quantity).toFixed(2)
                }
                if (this.invoice.company_invoice_currency == 'iqd') {
                    this.temp_item.last_price = this.temp_item.last_price * (this.invoice.company_invoice_dollar_price /
                        100)
                }
            },
            checkBarcode() {
                let check = this.items.filter(f => {
                    return f.item_barcode == this.invoice_item.item_barcode
                })
                if (check[0]) {
                    this.invoice_item.item_id = check[0].item_id
                    this.invoice_item.item_unit = check[0].item_unit
                    this.invoice_item.item_unit_tak = check[0].item_unit_tak
                    this.invoice_item.item_quantity = check[0].item_quantity
                    this.getItem(this.invoice_item.item_id)
                } else {
                    this.invoice_item.item_id = undefined
                }
            },
            getItem(id) {
                this.temp_item = {
                    last_price: 0,
                    last_count: 0
                }

                this.invoice_item.last_price = 0
                this.invoice_item.last_count = 0

                axios.post('items/getAvailable.php', {
                        item_id: id
                    })
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            let item = r.data.items ? r.data.items[0] : {}
                            this.invoice_item.last_price = item.item_amount
                            this.invoice_item.last_count = parseFloat(item.available_qty)

                            this.temp_item.last_price = item.item_amount
                            if (this.invoice.company_invoice_currency == 'iqd') {
                                this.temp_item.last_price = this.temp_item.last_price * (this.invoice
                                    .company_invoice_dollar_price / 100)
                            }
                            this.temp_item.last_count = parseFloat(item.available_qty)
                            this.checkLast()
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            checkItemID() {
                let check = this.items.filter(f => {
                    return f.item_id == this.invoice_item.item_id
                })
                if (check[0]) {
                    this.invoice_item.item_barcode = check[0].item_barcode
                    this.invoice_item.item_unit = check[0].item_unit
                    this.invoice_item.item_unit_tak = check[0].item_unit_tak
                    this.invoice_item.item_quantity = check[0].item_quantity
                    this.getItem(this.invoice_item.item_id)
                } else {
                    this.invoice_item.item_barcode = undefined
                }
            },
            getcompany_invoice_items() {
                axios.post('company_invoice_items/read.php', {
                        invoice_id: this.invoice_id
                    })
                    .then(r => {
                        // console.log(r);
                        this.invoice = r.data.wasl
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addcompany_invoice_items() {
                let params = this.invoice_item
                params.company_invoice_id = this.invoice_id
                let count = this.invoice_item.company_invoice_items_qty
                let iqd = this.invoice_item.company_invoice_items_iqd
                let usd = this.invoice_item.company_invoice_items_usd
                if (this.invoice_item.buy_type == 'karton') {
                    count = this.invoice_item.company_invoice_items_qty * this.invoice_item.item_quantity
                    params.company_invoice_items_qty = count

                    iqd = (this.invoice_item.company_invoice_items_iqd / this.invoice_item.item_quantity)
                    usd = (this.invoice_item.company_invoice_items_usd / this.invoice_item.item_quantity)

                    params.company_invoice_items_iqd = iqd
                    params.company_invoice_items_usd = usd
                }
                params.dollar_price = this.invoice.company_invoice_dollar_price
                params.currency = this.invoice.company_invoice_currency
                axios.post('company_invoice_items/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.invoice_item = {
                                company_invoice_items_usd: 0,
                                company_invoice_items_iqd: 0,
                                buy_type: 'karton'
                            }
                            this.getcompany_invoice_items()
                            this.msg = {
                                model: true,
                                text: 'کاڵا  زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            console.log(r);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletecompany_invoice_items() {
                let params = {
                    company_invoice_items_id: this.selected_company_invoice_items.company_invoice_items_id
                }
                axios.post('company_invoice_items/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getcompany_invoice_items()
                            this.msg = {
                                model: true,
                                text: '  کاڵا سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opencompany_invoice_items(item, type) {
                this.selected_company_invoice_items = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                }
            }
        },
        mounted() {
            this.invoice_id = this.$route.params.id;
            $('#deleteModal').modal('hide')
            this.getcompany_invoice_items()
        },
    }
</script>
<style>
    .my-table {
        width: 100%;
        border: 1px solid black;
    }

    .my-table th {
        padding: 10px;
        background-color: rgb(255, 230, 0);
        color: black;
    }

    .my-table td {
        padding: 5px;
    }

    .wasl-table {
        width: 100%;
        border: 1px solid black;
    }

    .wasl-table th {
        padding: 10px;
        background-color: rgb(255, 230, 0);
    }
</style>